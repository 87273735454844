import {cookie} from "../helper";

export const login = async (email: string, password: string) => {
    const request = await fetch( `${ process.env.REACT_APP_API_ORIGIN }/2.0.0/users/login`, {
        body: JSON.stringify({
            email: email,
            password: password,
            deviceType: 1
        }),
        headers: {
            "Content-Type": "application/json"
        },
        method: `POST`
    } )

    if ( request.status !== 200 ) {
        return false;
    }

    const body = await request.json();
    cookie(`token`, body.session.token);

    return true;
}