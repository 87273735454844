import {cookie} from "../helper";

export const appleLogin = async (token: string) => {
    const request = await fetch( `${ process.env.REACT_APP_API_ORIGIN }/2.0.0/users/apple-login?from=web&client_id=${ process.env.REACT_APP_APPLE_CLIENT_ID }`, {
        body: JSON.stringify({
            code: token,
            deviceType: 1,
            firstName: ` `,
            lastName: ` `
        }),
        headers: {
            "Content-Type": "application/json"
        },
        method: `POST`
    } )

    if ( request.status !== 201 ) {
        return false;
    }

    const body = await request.json();
    cookie(`token`, body.session.token);

    return true;
}