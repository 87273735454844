import './password-reset.scss'
import { useLoaderData } from 'react-router-dom'
import { useState, FormEvent } from 'react'

export const PasswordReset = () => {

    const token = useLoaderData() as string | null

    const [ passwordChanged, setPasswordChanged ] = useState( false )

    const [ password, setPassword ] = useState( `` )
    const [ confirmPassword, setConfirmPassword ] = useState( `` )

    const [ warningMessage, setWarningMessage ] = useState( `` )

    const onSubmit = async ( event: FormEvent ) => {

        event.preventDefault()

        if ( password !== confirmPassword ) {

            setWarningMessage( `A megadott két jelszó nem egyezik meg!` )
            return

        }

        const request = await fetch( `${ process.env.REACT_APP_API_ORIGIN }/2.0.0/users/password-reset/${ token }`, {
            body: JSON.stringify( { password } ),
            headers: { 'Content-Type': 'application/json' },
            method: `POST`
        } )

        if ( request.status === 200 ) {

            setPasswordChanged( true )

        } else {

            setWarningMessage( `Váratlan hiba történt a jelszóváltoztatás közben. Kérjük, próbáld újra később!` )

        }

    }

    return (

        <main className="password-reset">

            <header>

                <div className="container">

                    <img alt="" className="logo-full" src="/logo.svg" />

                </div>

            </header>

            <div className="container container--center">

                <img alt="" className="logo" src="/dk-logo.svg" />

                { token === null ? (

                    <>

                        <h2>Érvénytelen link!</h2>

                        <div className="info">A link amire kattintottál, nem érvényes. Próbálkozz újra az elfelejtett jelszó funkció használatával!</div>

                    </>

                ) : null }

                { token !== null && passwordChanged ? (

                    <>

                        <h2>Sikeres jelszómódosítás!</h2>

                        <div className="info">Mostmár ezzel a jelszóval tudsz belépni az applikációba!</div>

                    </>

                ) : null }

                { token !== null && !passwordChanged ? (

                    <>

                        <h2>Jelszó pótlás!</h2>

                        <div className="info">Kérlek add meg az új jelszavaidat, amellyel később bejelentkezel majd a DK Plusz applikációba!</div>

                        <form onSubmit={ onSubmit }>

                            <input type="password" value={ password } placeholder="Új jelszó" onChange={ e => setPassword( e.target.value ) } />
                            <input type="password" value={ confirmPassword } placeholder="Új jelszó mégegyszer" onChange={ e => setConfirmPassword( e.target.value ) } />
                            <div className="warning-message">{ warningMessage }</div>
                            <input type="submit" value="Rendben" />

                        </form>

                    </>

                ) : null }

            </div>

            <footer>

                <div className="container">

                    <a href="https://dkp.hu/adat/dkplusz.html" target="_blank">Adatvédelmi nyilatkozat</a>

                    <a href="https://dkp.hu/adat/dkplusz_hasznalati.html" target="_blank">Használati feltételek</a>

                    <a href="https://kisokos.dkplusz.hu/kisokos.pdf" target="_blank">DK Plusz súgó</a>

                </div>

            </footer>

        </main>

    )

}
