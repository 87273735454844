import "./profile.scss";
import {useLoaderData, useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {cookie} from "../../helper";
import {Modal} from "../modal";
import {deleteUser} from "../../action";

export const Profile = () => {
    const navigate = useNavigate();
    const user: any = useLoaderData();

    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        if (user === null) {
            navigate(`/belepes`);
        }
    }, []);

    const logout = () => {
        cookie(`token`, ``);
        navigate(`/belepes`);
    }

    const confirmDelete = async () => {
        if (await deleteUser()) {
            logout();
        } else {
            alert(`Sajnos nem sikerült törölnünk az adataidat egy technikai hiba miatt!`);
        }
    }

    return (

        <main className="profile">

            <header>

                <div className="container">

                    <img alt="" className="logo-full" src="/logo.svg" />
                    <div className="user">
                        <span>{ user?.email || `` }</span>
                        <img alt="Kilépés" src="/logout.svg" onClick={ logout } />
                    </div>

                </div>

            </header>

            <div className="container">

                <h2>Ez a DK Plusz fiókod!</h2>

                <div className="info">
                    Lehetőséged van DK Plusz felhasználód törlésére. Ebben az esetben minden adatodat és tapasztalati
                    pontodat töröljük, amelyet megadtál/megszereztél az applikációban.
                </div>

                <button onClick={ () => setShowModal(true) }>Felhasználó törlése</button>

            </div>

            <footer>

                <div className="container">

                    <a href="https://dkp.hu/adat/dkplusz.html" target="_blank">Adatvédelmi nyilatkozat</a>

                    <a href="https://dkp.hu/adat/dkplusz_hasznalati.html" target="_blank">Használati feltételek</a>

                    <a href="https://kisokos.dkplusz.hu/kisokos.pdf" target="_blank">DK Plusz súgó</a>

                </div>

            </footer>

            { showModal ? (

                <Modal>

                    <div className="modal-title">Biztosan törlöd a felhasználód a DK Plusz alkalmazásból?</div>
                    <div className="modal-body">
                        Minden megszerzett tapasztalati pont és kitünetés is elveszik!
                    </div>
                    <div className="modal-footer">
                        <button onClick={ () => setShowModal(false) }>Mégsem</button>
                        <button className="warning" onClick={ confirmDelete }>Törlés</button>
                    </div>

                </Modal>

            ) : null }

        </main>

    )

}
