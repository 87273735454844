import { LoaderFunctionArgs } from 'react-router-dom'
import {cookie} from "../helper";

export const profileLoader = async ( args: LoaderFunctionArgs ) => {

    const token = cookie(`token`);

    const request = await fetch( `${ process.env.REACT_APP_API_ORIGIN }/2.0.0/users/me`, {
        headers: {
            "Authorization": `Bearer ${ token }`
        }
    })
    if (request.status !== 200) {
        return null;
    }
    const body = await request.json();
    return body.user;

}