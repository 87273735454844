import {cookie} from "../helper";

export const facebookLogin = async (token: string) => {
    const request = await fetch( `${ process.env.REACT_APP_API_ORIGIN }/2.0.0/users/facebook-login?from=web`, {
        body: JSON.stringify({
            accessToken: token,
            deviceType: 1
        }),
        headers: {
            "Content-Type": "application/json"
        },
        method: `POST`
    } )

    if ( request.status !== 201 ) {
        return false;
    }

    const body = await request.json();
    cookie(`token`, body.session.token);

    return true;
}