import React from 'react'
import ReactDOM from 'react-dom/client'
import { RouterProvider } from 'react-router-dom'
import { router } from './router'
import {GoogleOAuthProvider} from "@react-oauth/google";

ReactDOM.createRoot( document.getElementById( 'application' ) as HTMLElement ).render(

    <React.StrictMode>

        <RouterProvider router={ router } />

    </React.StrictMode>

)
