import {cookie} from "../helper";

export const deleteUser = async () => {
    const request = await fetch( `${ process.env.REACT_APP_API_ORIGIN }/2.0.0/users`, {
        headers: {
            "Authorization": `Bearer ${ cookie(`token`) }`
        },
        method: `DELETE`
    } )

    return ( request.status === 200 );
}