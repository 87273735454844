import {FormEvent, useEffect, useState} from "react";
import "./login.scss";
import {appleLogin, facebookLogin, googleLogin, login} from "../../action";
import { useNavigate } from "react-router-dom";
import FacebookLogin, {FailResponse, SuccessResponse} from "@greatsumini/react-facebook-login";
import AppleLogin from "react-apple-login";

let googleLoginWrapperButton: HTMLElement | null = null
let triedAppleLogin: boolean = false

export const Login = () => {
    const navigate = useNavigate();

    const [email, setEmail] = useState(``);
    const [password, setPassword] = useState(``);
    const [warningMessage, setWarningMessage] = useState(``);

    const onSubmit = async (event: FormEvent) => {
        event.preventDefault();
        if (await login(email, password)) {
            navigate(`/felhasznalo`);
        } else {
            setWarningMessage(`Hibás e-mail cím vagy jelszó!`);
        }
    }

    const handleGoogleButtonClick = () => {

        const google: any = (window as any).google

        if (googleLoginWrapperButton === null) {

            google.accounts.id.initialize({
                client_id: `516401748398-n1i1ddfr16kr4c8e3vghe7mv9mtqjftu.apps.googleusercontent.com`,
                ux_mode: "popup",
                callback: handleGoogleLogin
            });

            const googleLoginWrapper = document.createElement("div");
            // Or you can simple hide it in CSS rule for custom-google-button
            googleLoginWrapper.style.display = "none";
            googleLoginWrapper.classList.add("custom-google-button");

            // Add the wrapper to body
            document.body.appendChild(googleLoginWrapper);

            // Use GSI javascript api to render the button inside our wrapper
            // You can ignore the properties because this button will not appear
            google.accounts.id.renderButton(googleLoginWrapper, {
                type: "icon",
                width: "200",
            });

            googleLoginWrapperButton = googleLoginWrapper.querySelector("div[role=button]");

        }

        googleLoginWrapperButton?.click()

    }

    const handleGoogleLogin = async (credentials: any) => {
        if (await googleLogin(credentials.credential)) {
            navigate(`/felhasznalo`)
        } else {
            alert(`Sajnos nem sikerült a bejelentkezés! Vélhetően még nem jelentkeztél be az adott fiókkal az alkalmazásban!`);
        }
    }

    const handleFacebookLogin = async (response: SuccessResponse) => {
        if (await facebookLogin(response.accessToken)) {
            navigate(`/felhasznalo`)
        } else {
            alert(`Sajnos nem sikerült a bejelentkezés! Vélhetően még nem jelentkeztél be az adott fiókkal az alkalmazásban!`);
        }
    }

    const handleFacebookFail = (error: FailResponse) => {
        alert(`Sajnos nem sikerült a bejelentkezés!`);
    }

    const handleAppleLogin = async (response: any) => {
        if (triedAppleLogin) {
            return;
        }

        triedAppleLogin = true;
        if (await appleLogin(response.code)) {
            navigate(`/felhasznalo`)
        } else {
            alert(`Sajnos nem sikerült a bejelentkezés! Vélhetően még nem jelentkeztél be az adott fiókkal az alkalmazásban!`);
        }
    }

    return (

        <main className="login">

            <header>

                <div className="container">

                    <img alt="" className="logo-full" src="/logo.svg" />

                </div>

            </header>

            <div className="space"></div>

            <div className="container container--center">

                <img alt="" className="logo" src="/dk-logo.svg" />

                <h2>Ez a DK Plusz fiókod!</h2>

                <div className="info">Lépj be a DK Plusz felhasználói fiókodba!</div>

                <form onSubmit={ onSubmit }>

                    <input type="email" value={ email } placeholder="e-mail cím" onChange={ e => setEmail( e.target.value ) } />
                    <input type="password" value={ password } placeholder="jelszó" onChange={ e => setPassword( e.target.value ) } />
                    <div className="warning-message">{ warningMessage }</div>
                    <input type="submit" value="Belépés" />

                </form>

                <div className="social-login">

                    <div className="social-button google-social-button" onClick={ handleGoogleButtonClick }>
                        <img alt="Google login" src="/google.svg" />
                        <span>Folytatás Google-fiókkal</span>
                    </div>

                    <FacebookLogin
                        appId={ process.env.REACT_APP_FACEBOOK_APP_ID || `` }
                        onSuccess={ handleFacebookLogin }
                        onFail={ handleFacebookFail }
                        render={({ onClick, logout }) => (
                            <div className="social-button" onClick={ onClick }>
                                <img alt="Facebook login" src="/facebook.svg" />
                                <span>Folytatás Facebookkal</span>
                            </div>
                        )}
                    />

                    <AppleLogin clientId={ process.env.REACT_APP_APPLE_CLIENT_ID || `` } redirectURI={ `${ process.env.REACT_APP_ORIGIN }/belepes` } responseMode="query" callback={ handleAppleLogin } render={ renderFunctions => (
                        <div className="social-button" onClick={ renderFunctions.onClick }>
                            <img alt="Apple login" src="/apple.svg" />
                            <span>Folytatás Apple-lel</span>
                        </div>
                    )} />

                </div>
            </div>

            <div className="space"></div>

            <footer>

                <div className="container">

                    <a href="https://dkp.hu/adat/dkplusz.html" target="_blank">Adatvédelmi nyilatkozat</a>

                    <a href="https://dkp.hu/adat/dkplusz_hasznalati.html" target="_blank">Használati feltételek</a>

                    <a href="https://kisokos.dkplusz.hu/kisokos.pdf" target="_blank">DK Plusz súgó</a>

                </div>

            </footer>

        </main>

    )

}
