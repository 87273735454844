import './user-activation.scss'
import { useLoaderData } from 'react-router-dom'

export const UserActivation = () => {

    const success = useLoaderData() as boolean

    return (

        <main className="user-activation">

            <header>

                <div className="container">

                    <img alt="" className="logo-full" src="/logo.svg" />

                </div>

            </header>

            <div className="container container--center">

                <img alt="" className="logo" src="/dk-logo.svg" />

                <h2>
                    { success ? `Sikeres aktiválás!` : `Sikertelen aktiválás` }
                </h2>

                <div className="info">
                    { success ? `Most már be tudsz lépni az mobilalkalmazásba ezzel az e-mailcímmel!` : `Lépj vissza a DK Pluszba és kérj új aktiváló e-mailt!` }
                </div>

            </div>

            <footer>

                <div className="container">

                    <a href="https://dkp.hu/adat/dkplusz.html" target="_blank">Adatvédelmi nyilatkozat</a>

                    <a href="https://dkp.hu/adat/dkplusz_hasznalati.html" target="_blank">Használati feltételek</a>

                    <a href="https://kisokos.dkplusz.hu/kisokos.pdf" target="_blank">DK Plusz súgó</a>

                </div>

            </footer>

        </main>

    )

}
