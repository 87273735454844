import { createBrowserRouter } from 'react-router-dom'
import React from 'react'
import {App, PasswordReset, Profile, UserActivation} from './component'
import {passwordResetLoader, profileLoader, userActivationLoader} from './loader'
import {Login} from "./component/login";

export const router = createBrowserRouter( [
    {
        element: <App />,
        path: `/`
    },
    {
        element: <PasswordReset />,
        loader: passwordResetLoader,
        path: `/jelszo-helyreallitasa/:token`
    },
    {
        element: <UserActivation />,
        loader: userActivationLoader,
        path: `/aktivalas/:token`
    },
    {
        element: <Login />,
        path: `/belepes`
    },
    {
        element: <Profile />,
        loader: profileLoader,
        path: `/felhasznalo`
    }
] )