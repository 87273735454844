import '../../font/font.scss'
import './app.scss'

export const App = () => {

    return (

        <main className="home">

            <img alt="" className="background background--desktop" src="/background.png" />
            <img alt="" className="background background--mobile" src="/background-mobile.png" />

            <div className="container">

                <a className="logo" href="https://dkp.hu/" target="_blank">

                    <img alt="Demokratikus Koalíció párt logója" src="/logo.svg" />

                </a>

                <div className="content">

                    <h1>

                        Töltsd le a DK Plusz mobilalkalmazást!

                    </h1>

                    <div className="description">

                        A DK Plusz mobilalkalmazás a szemünk láttára formálja újra, amit eddig a politikai részvételről tudtunk. Alapjaiban gondoltuk újra az erről alkotott képünket. Az applikáció segítségével életre hívtuk Magyarország első olyan közösségét, amelynek tagjai azonnal jutalmazva vannak a kormányváltásért végzett munkájukért.

                    </div>

                    <div className="links">

                        <a href="https://apps.apple.com/us/app/dk-plusz/id6448105041" target="_blank">

                            <img alt="Download on the App Store" src="/app-store.png" width="186" />

                        </a>

                        <a href="https://play.google.com/store/apps/details?id=hu.dk.app" target="_blank">

                            <img alt="Get it on Google Play" src="/google-play.png" width="182" />

                        </a>

                    </div>

                    <div className="info">

                        A DK Plusz egy eszköz, ami ösztönöz, jutalmaz, tájékoztat és lehetővé teszi számunkra, hogy látható és érzékelhető módon vegyünk részt a politikai folyamatokban. Töltsd le a DK Pluszt, versengj másokkal és nyerd el a jutalmakat!

                    </div>

                    <div className="cta">

                        Vegyél részt Magyarország politikai kultúrájának megújításában!

                    </div>

                </div>

                <footer>

                    <div>

                        <a href="https://dkp.hu/adat/dkplusz.html" target="_blank">Adatvédelmi nyilatkozat</a>

                    </div>

                    <div>

                        <a href="https://dkp.hu/adat/dkplusz_hasznalati.html" target="_blank">Használati feltételek</a>

                    </div>

                    <div>

                        <a href="https://kisokos.dkplusz.hu/kisokos.pdf" target="_blank">DK Plusz súgó</a>

                    </div>

                </footer>

            </div>

        </main>

    )

}
