import { ReactNode } from "react";
import "./modal.scss";

interface ModalProps {
    children: ReactNode[]
}

export const Modal = (props: ModalProps) => {
    return (
        <div className="modal-container">
            <div className="modal">
                { props.children }
            </div>
        </div>
    )
}